.App {
    text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

h5 {
    margin: 5px;
}

.wall {
    stroke-width: 2px;
    stroke: black;
}

.node {
    r: 5px;
    cursor: pointer;
}

.node-selected {
    r: 7px;
    cursor: pointer;
}

.feature-edge {
    stroke-width: 3px;
    stroke: grey;
    cursor: pointer;
}

.feature-edge-selected {
    stroke-width: 4px;
    stroke: darkorange;
    cursor: pointer;
    stroke-dasharray: 5 2;
}

.app-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 4fr;
    grid-template-areas:
        "header header header"
        "navtop   legend content"
        "navmiddle   legend content"
        "navbuttons  legend  content"
        "matrix  matrix content"
        "footer footer content";
    row-gap: 5px;
    column-gap: 10px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 25px;
}

#room-picker-nav {
    grid-area: navtop;
    height: min-content;
}

#footer {
    grid-area: footer;
    height: stretch;
}

#room-picker-local-nav {
    grid-area: navmiddle;
    height: min-content;
}

#room-buttons {
    display: grid;
    grid-area: navbuttons;
    height: min-content;
    width: min-content;
}

    #room-buttons button {
        margin-bottom: 5px;
    }

#room-legend-nav {
    display: grid;
    grid-area: legend;
    height: min-content;
}

#room-matrix {
    display: grid;
    grid-area: matrix;
}

#room-matrix-detail {
    height: min-content;
    margin-top: 9px;
}

.nav-top {
    grid-area: navtop;
}

ul {
    list-style: none;
}

.legend-row {
}

.legend-key {
    color: black;
    display: inline-block;
    font-size: 14px;
    margin-bottom: 3px;
}

.legend-value {
    display: inline-block;
    width: 10%;
    font-size: 24px;
    margin-bottom: -5px;
    margin-top: -10px;
}

.main-head {
    grid-area: header;
    background-color: #fff;
}

.content {
    grid-area: content;
}

.main-nav {
    grid-area: nav;
}

.side {
    grid-area: sidebar;
}

#room_picker-nav {
    width: 300px;
}

#room-matrix {
    font-size: 6px;
    font-family: Verdana, monospace;
}

.matrix-off {
    color: #ccc;
}

.matrix-on {
    color: red;
    font-weight: bold;
}

svg {
    background-color: #FFF;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

    svg:not(.active):not(.ctrl) {
        cursor: crosshair;
    }

path.link {
    fill: none;
    stroke: #000;
    stroke-width: 4px;
    cursor: default;
}

svg:not(.active):not(.ctrl) path.link {
    cursor: pointer;
}

path.link.selected {
    stroke-dasharray: 10,2;
}

path.link.dragline {
    pointer-events: none;
}

path.link.hidden {
    stroke-width: 0;
}

circle.node.reflexive {
    stroke: #000 !important;
    stroke-width: 2.5px;
}

text {
    font: 12px sans-serif;
    pointer-events: none;
}

    text.id {
        text-anchor: middle;
        font-weight: bold;
    }

h4 {
    font-size: 125%;
    margin: 0px 0px 10px 0px;
}

.project-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        "header"
        "content";
    row-gap: 5px;
    column-gap: 10px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 25px;
}

.project-picker-detail {
    border: 1px dotted black;
    grid-area: content;
}

#project-picker {
    display: table;
    width: 100%;
}

    #project-picker div {
        padding: 4px;
        width: stretch;
    }